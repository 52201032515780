import React, { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import {
  Typography,
  Card,
  Stack,
  FormControl,
  FormHelperText,
  IconButton
} from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import LinearProgress, {
  LinearProgressProps
} from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import axios, { AxiosRequestConfig } from 'axios'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import Translations from '@/layouts/components/Translations'
import { useMutation } from 'react-query'
import { upload } from '@/@core/services/app.service'
import { Close, Delete } from 'mdi-material-ui'

const schema = yup.object().shape({
  name: yup.string().min(3).required()
})

interface Rol {
  id?: number
  name: string
  path: string
  file: File | null
}

interface RolDialogProps {
  open: boolean
  onClose: () => void
  rolToEdit: Rol | null
  onSave: (Rol: Rol) => void
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>

      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

const RolDialog: React.FC<RolDialogProps> = ({
  open,
  onClose,
  rolToEdit,
  onSave
}) => {
  const defaultValues = {
    name: rolToEdit ? rolToEdit.name : '',
    file: rolToEdit ? rolToEdit.file : null,
    path: rolToEdit ? rolToEdit.path : ''
  }

  const [Rol, setRol] = useState(defaultValues)
  const [process, setProcess] = React.useState<number>(0)
  const [uploading, setUploading] = useState(false)
  const { t } = useTranslation()

  // ** Hooks
  const {
    control: Control,
    handleSubmit: handleSubmit,
    formState: { errors: Errors },
    reset,
    getValues
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  })

  const handleSave = (data: any) => {
    if (rolToEdit) {
      // 编辑模式
      onSave({
        ...rolToEdit,
        name: Rol.name,
        file: Rol.file
      })
    } else {
      // 创建模式
      console.log(Rol, data)

      onSave(data)
    }

    onClose()

    setRol({
      path: '',
      file: null,
      name: ''
    })

    reset(defaultValues)

    setProcess(0)
  }

  // const Upload = useMutation(upload, {
  //   onSuccess: (data: string, params) => {
  //     if (data) {

  //     }
  //   }
  // })

  const handleUpload = async (file: File) => {
    if (file) {
      setUploading(true)

      const newName = file.name.replace(/\s+/g, '_')

      // 创建一个新的 Blob 对象（这里我们简单地返回原始文件的 Blob）

      const newBlob = new Blob([file], { type: file.type })

      // 使用 File 构造函数创建一个新的 File 对象，并指定新的名称

      const newFile = new File([newBlob], newName, {
        type: file.type,

        lastModified: file.lastModified
      })
      const formData = new FormData()

      formData.append('file', newFile)

      const config: AxiosRequestConfig = {
        onUploadProgress: (progressEvent: any) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          )

          setProcess(progress)
        }
      }

      try {
        const { data } = await axios.post(
          '/api/oss/beds_uploads/',
          formData,
          config
        )

        setRol({
          path: newFile.name,
          name: newFile.name,
          file: newFile
        })

        console.log(getValues())
        const defaultValues = getValues()

        if (!defaultValues.name)
          reset({
            file: newFile,
            path: newFile.name,
            name: newFile.name
          })
        else
          reset({
            file: newFile,
            path: newFile.name,
            name: defaultValues.name
          })

        setUploading(false)
      } catch (error) {
        console.error('Upload error:', error)

        setUploading(false)
      }
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      // setRol({
      //   ...Rol,
      //   name: event.target.files[0].name,
      //   file: event.target.files[0]
      // })

      handleUpload(event.target.files[0])
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()

        setRol({
          path: '',
          file: null,
          name: ''
        })

        reset(defaultValues)

        setProcess(0)
      }}
      fullWidth
    >
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogTitle>
          <Translations
            text={rolToEdit ? 'Edit ROI' : 'Upload New ROI'}
          ></Translations>
        </DialogTitle>

        <DialogContent dividers>
          <FormControl fullWidth>
            <Controller
              name="name"
              control={Control}
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <TextField
                  value={value}
                  onChange={(event) => {
                    setRol({ ...Rol, name: event.target.value })

                    onChange(event)
                  }}
                  error={Boolean(Errors.name)}
                  aria-describedby="stepper-linear--name"
                  margin="dense"
                  label={t('name')}
                />
              )}
            />

            {Errors.name && (
              <FormHelperText
                sx={{ color: 'error.main' }}
                id="stepper-linear--name"
              >
                {Errors.name.message}
              </FormHelperText>
            )}
          </FormControl>

          <Typography>
            ROI
            <Translations text="Template File"></Translations>:
            <Button>
              <Translations text="view"></Translations>
            </Button>
          </Typography>

          <Card>
            <CardContent>
              <Stack direction={'row'} alignItems="center" spacing={2}>
                <Button size="small" variant="contained" component="label">
                  <input
                    onChange={handleFileChange}
                    type="file"
                    hidden
                    accept=".tsv, .bed, .csv,.bed.formated,.bed.bin.formated"
                  />

                  <Translations text="Browse"></Translations>
                </Button>

                <Box>
                  {Rol.file ? (
                    <Typography>
                      {Rol.file.name}

                      <IconButton
                        onClick={() => {
                          setRol({
                            ...Rol,
                            file: null
                          })

                          setProcess(0)
                        }}
                      >
                        <Close sx={{ fontSize: '1.125rem' }} />
                      </IconButton>
                    </Typography>
                  ) : (
                    <Typography>{t('Unselected File')}</Typography>
                  )}
                </Box>
              </Stack>

              <LinearProgressWithLabel value={process} />
            </CardContent>
          </Card>
        </DialogContent>

        <DialogActions>
          <Stack
            width={'100%'}
            alignItems="center"
            justifyContent={'center'}
            direction={'row'}
            spacing={6}
            marginTop={4}
          >
            <Button
              variant="outlined"
              onClick={() => {
                onClose()

                setRol({
                  path: '',
                  file: null,
                  name: ''
                })

                reset(defaultValues)

                setProcess(0)
              }}
              color="primary"
            >
              <Translations text="cancel"></Translations>
            </Button>

            <Button variant="contained" type="submit" color="primary">
              <Translations text="save"></Translations>
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default RolDialog
