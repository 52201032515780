// ** React Imports
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography, { TypographyProps } from '@mui/material/Typography'

import Link from '@mui/material/Link'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import {
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack
} from '@mui/material'
import Translations from '@/layouts/components/Translations'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import {
  assumeRole,
  createCatalog,
  createUserCustomUploads,
  deleteUserCustomUploads,
  editUserCustomUploads,
  getUserCustomUploads,
  getUserDirectUploads,
  getUserFiles,
  UserCustomUploads,
  UserDirectUploads
} from '@/@core/services/app.service'
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid'
import { NObject } from '@/@core/components/filter-variants'
import { formatDate, unique } from '@/@core/utils/utils'
import React from 'react'
import { FileDocument, FolderOpen, Home } from 'mdi-material-ui'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  },
  '& .MuiPaper-root': {
    maxWidth: 1200,
    minHeight: 500
  }
}))

interface FileProp {
  name: string
  type: string
  size: number
}

interface Props {
  fn?: (file: File[]) => void
  uploadFn?: (file: File[]) => void
  accept?: string
  disableList?: boolean
  sx?: any
}

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

const TypographyEllipsis = styled(Typography)(() => ({
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: '0.875rem'
}))

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const LocalFileUploaderMultiple = React.forwardRef((props: Props, ref) => {
  const { t } = useTranslation()

  // ** State
  const [files, setFiles] = useState<any[]>([])
  const [directorys, setDirectorys] = useState<any[]>([])
  const [localFileListOpen, setLocalFileListOpen] = useState(false)
  const [localFileList, setLocalFileList] = useState<any[]>([])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('File Name'),
      flex: 1,
      renderCell: (row) => {
        return (
          <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems={'center'}
          >
            {row.row.type === 'directory' ? (
              <IconButton
                sx={{
                  color: 'rgb(254,203,7)'
                }}
              >
                <FolderOpen />
              </IconButton>
            ) : (
              <IconButton>
                <FileDocument />
              </IconButton>
            )}

            <Typography
              sx={{
                color: '#0064c8',
                ':hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}
              onClick={async () => {
                if (row.row.type === 'directory') {
                  await fetchUserLocalFiles.mutateAsync(
                    `${directorys.join('/')}/${row.row.name}/`
                  )

                  setDirectorys([...directorys, row.row.name])
                }
              }}
            >
              {row.row.name}
            </Typography>
          </Stack>
        )
      }
    },
    {
      field: 'size',
      headerName: t('Size'),
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      renderCell: (row) => {
        const size = row.row.size

        if (size) {
          return (
            <TypographyEllipsis
              sx={{
                textAlign: 'left'
              }}
            >
              {`${(size / 1024 / 1024).toFixed(2)}MB`}
            </TypographyEllipsis>
          )
        } else {
          return (
            <TypographyEllipsis
              sx={{
                textAlign: 'left'
              }}
            >
              {'未统计'}
            </TypographyEllipsis>
          )
        }
      }
    },
    {
      field: 'lastModified',
      headerName: t('submitAt'),
      width: 180,
      renderCell: (row) => {
        return ''
      }
    },
    {
      field: 'actions',
      headerName: t('Actions'),
      width: 120,
      renderCell: (row) => {
        return ''
      }
    }
  ]

  const getLocalList = async () => {
    await fetchUserLocalFiles.mutateAsync(
      directorys.length ? directorys.join('/') : ''
    )
  }

  const handleLocalClick = async () => {
    setLocalFileListOpen(true)

    await fetchUserLocalFiles.mutateAsync('')
  }

  const fetchUserLocalFiles = useMutation({
    mutationFn: async (path: string) =>
      await getUserFiles({
        parent: path
      }),
    onSuccess: (data) => {
      if (data) {
        setLocalFileList(
          data
            .map((i: any, index: number) => {
              return {
                id: i.name,
                ...i
              }
            })
            .sort((a: any, b: any) => {
              if (a.type === 'directory' && b.type === 'file') {
                return -1 // directory 排在 file 前面
              } else if (a.type === 'file' && b.type === 'directory') {
                return 1 // file 排在 directory 后面
              } else {
                return 0 // 如果 type 相同，则保持相对顺序（或可以根据需要进一步排序）
              }
            })
        )
      }
    }
  })

  React.useEffect(() => {
    console.log(localFileList)
  }, [localFileList])

  React.useImperativeHandle(ref, () => {
    return {
      handleLocalClick
    }
  })

  return (
    <Fragment>
      <BootstrapDialog
        onClose={() => {
          setLocalFileListOpen(false)

          setLocalFileList([])

          setFiles([])

          setDirectorys([])
        }}
        aria-labelledby="customized-dialog-title"
        open={localFileListOpen}
      >
        <DialogTitle>
          <Translations text={'文件列表'}></Translations>
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={() => {
            setLocalFileListOpen(false)

            setLocalFileList([])

            setFiles([])

            setDirectorys([])
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers sx={{ width: 1200 }}>
          <Stack height={'100%'}>
            <Stack
              my={2}
              justifyContent={'space-between'}
              direction="row"
              alignItems={'center'}
            >
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  onClick={async () => {
                    await fetchUserLocalFiles.mutateAsync('')

                    setDirectorys([])
                  }}
                >
                  <IconButton>
                    <Home />
                  </IconButton>
                </Link>

                {directorys.map((item, index) => {
                  if (index === directorys.length - 1) {
                    return (
                      <Typography sx={{ color: 'text.primary' }}>
                        {item}
                      </Typography>
                    )
                  } else {
                    return (
                      <Link
                        underline="hover"
                        color="inherit"
                        onClick={async () => {
                          const index = directorys.findIndex((i) => i === item)
                          const arr = directorys.slice(0, index + 1)
                          const path = directorys.slice(0, index + 1).join('/')

                          console.log(path)

                          setDirectorys(arr)

                          await fetchUserLocalFiles.mutateAsync(`${path}/`)
                        }}
                      >
                        {item}
                      </Link>
                    )
                  }
                })}
              </Breadcrumbs>

              <Box textAlign={'right'}>
                <Button
                  onClick={() => {
                    getLocalList()
                  }}
                  variant="contained"
                  size="small"
                >
                  {t('Refresh')}
                </Button>
              </Box>
            </Stack>

            <DataGrid
              rows={localFileList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10
                  }
                }
              }}
              isRowSelectable={(params: GridRowParams) =>
                params.row.type !== 'directory'
              }
              pageSizeOptions={[5, 10, 20]}
              disableRowSelectionOnClick
              checkboxSelection
              rowSelectionModel={files.map((i) => i.name)}
              onRowSelectionModelChange={(rowKey) => {
                console.log(rowKey)

                const data = rowKey
                  .map((item) => {
                    return localFileList.find((i) => i.id == item)
                  })
                  .filter((i) => i && i.name)

                const others = files.filter(
                  (i) => !localFileList.some((j) => j.name === i.name)
                )

                console.log(others, files, data)

                setFiles(
                  unique([...data, ...others], 'id').filter((i) => i && i.name)
                )
              }}
              sx={{
                minHeight: 250,
                maxHeight: 500
              }}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Stack
            width={'100%'}
            direction={'row'}
            spacing={4}
            margin={'10px 0'}
            alignItems="center"
            justifyContent={'center'}
          >
            <Button
              autoFocus
              variant="outlined"
              onClick={() => {
                setLocalFileListOpen(false)

                setLocalFileList([])

                setFiles([])

                setDirectorys([])
              }}
            >
              {t('cancel')}
            </Button>

            <Button
              autoFocus
              variant="contained"
              onClick={() => {
                props.fn &&
                  props.fn(
                    files.map((i) => {
                      return {
                        size: 1,
                        ...i
                      }
                    })
                  )

                setLocalFileListOpen(false)

                setLocalFileList([])

                setFiles([])

                setDirectorys([])
              }}
            >
              {t('create')}
            </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </Fragment>
  )
})

export default LocalFileUploaderMultiple
