// ** React Imports
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react'
import React from 'react'

// ** Next Import
import Link from 'next/link'

// ** MUI Imports
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import Button from '@mui/material/Button'
import ListItem from '@mui/material/ListItem'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Typography, { TypographyProps } from '@mui/material/Typography'

// ** Icon Imports
import Icon from 'src/@core/components/icon'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { Stack } from '@mui/material'
import Translations from '@/layouts/components/Translations'
import PositionedSnackbar from '@/@core/components/message'

interface FileProp {
  name: string
  type: string
  size: number
}

interface Props {
  fn?: (file: File[]) => void
  uploadFn?: (file: File[]) => void
  accept?: string
  disableList?: boolean
  sx?: any
}

// Styled component for the upload image inside the dropzone area
const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const FileUploaderMultiple = React.forwardRef((props: Props, ref) => {
  // ** State
  const [files, setFiles] = useState<File[]>([])
  const inputRef = React.useRef<any>()
  const messageRef = React.useRef<any>()

  // ** Hooks
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      let isFiles = true

      setFiles(
        acceptedFiles
          .map((file: File) => Object.assign(file))
          .filter((file) => {
            const type = file.name.split('.').at(-1)

            if (props.accept?.includes(type)) return true
            else {
              isFiles = false

              return false
            }
          })
      )

      if (!isFiles)
        return messageRef?.current?.handleClick(
          '请添加指定后缀的样本文件，不兼容 Excel，tsv，csv，txt，word等格式',
          'warning'
        )
    }
  })

  useEffect(() => {
    props.fn && props.fn(files)
  }, [files])

  const renderFilePreview = (file: FileProp) => {
    if (file.type.startsWith('image')) {
      return (
        <img
          width={38}
          height={38}
          alt={file.name}
          src={URL.createObjectURL(file as any)}
        />
      )
    } else {
      return <Icon icon="mdi:file-document-outline" />
    }
  }

  const handleRemoveFile = (file: FileProp) => {
    const uploadedFiles = files
    const filtered = uploadedFiles.filter((i: FileProp) => i.name !== file.name)

    setFiles([...filtered])
  }

  useImperativeHandle(ref, () => {
    return {
      inputRef
    }
  })

  const fileList = files.map((file: FileProp) => (
    <ListItem key={file.name}>
      <div className="file-details">
        <div className="file-preview">{renderFilePreview(file)}</div>

        <div>
          <Typography className="file-name">{file.name}</Typography>

          <Typography className="file-size" variant="body2">
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>

      <IconButton onClick={() => handleRemoveFile(file)}>
        <Icon icon="mdi:close" fontSize={20} />
      </IconButton>
    </ListItem>
  ))

  const handleRemoveAllFiles = () => {
    setFiles([])
  }

  return (
    <Fragment>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} accept={props.accept} ref={inputRef} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            alignItems: 'center'
          }}
        >
          <Img alt="Upload img" src="/images/misc/upload.png" />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: ['center', 'center', 'inherit']
            }}
          >
            <HeadingTypography variant="h5">
              <Translations text="Drop files here or click to upload."></Translations>
            </HeadingTypography>

            <Typography
              color="textSecondary"
              sx={{ '& a': { color: 'primary.main', textDecoration: 'none' } }}
            >
              {/* Drop files here or click
              <Link href="/" onClick={(e) => e.preventDefault()}>
                {' '}
                browse
              </Link>{' '}
              thorough your machine */}
              <Translations text="Drop files here or click"></Translations>

              <Link href="/" onClick={(e) => e.preventDefault()}>
                <Translations text="browse"></Translations>
              </Link>

              <Translations text="thorough your machine"></Translations>
            </Typography>

            <Typography color="textSecondary" sx={{ color: 'red' }}>
              <Translations text="Make sure the sample name is in English to avoid analysis failure or upload anomalies"></Translations>
            </Typography>
          </Box>
        </Box>
      </div>

      {files.length && !props.disableList ? (
        <Fragment>
          <List>{fileList}</List>

          {/* <Stack
            className="buttons"
            marginBottom={4}
            direction="row"
            spacing={5}
            marginRight={2}
          >
            <Button
              color="error"
              variant="outlined"
              onClick={() => {
                props.uploadFn && props.uploadFn(files)

                // handleRemoveAllFiles()
              }}
            >
              <Translations text="Upload Files"></Translations>
            </Button>

            <Button variant="contained" onClick={handleRemoveAllFiles}>
              <Translations text="Remove All"></Translations>
            </Button>
          </Stack> */}
        </Fragment>
      ) : null}

      <PositionedSnackbar ref={messageRef} />
    </Fragment>
  )
})

export default FileUploaderMultiple
